function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { FeaturedQuote } from "@fireworx/types/sanity.types";
import Carousel from "@fireworx/components/carousel";
import Quote from "@fireworx/components/quote";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
type QuoteSectionProps = {
  quotes: FeaturedQuote[];
};
var _ref = process.env.NODE_ENV === "production" ? {
  name: "ezaoxa",
  styles: "--tw-bg-opacity:1;background-color:rgb(255 183 75 / var(--tw-bg-opacity));padding-bottom:38px"
} : {
  name: "g0x8o3-QuoteSection",
  styles: "--tw-bg-opacity:1;background-color:rgb(255 183 75 / var(--tw-bg-opacity));padding-bottom:38px;label:QuoteSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const QuoteSection = ({
  quotes
}: QuoteSectionProps) => {
  return _jsx("section", {
    css: _ref,
    children: _jsx(Carousel, {
      autoplay: true,
      items: quotes.map(quote => {
        return {
          id: quote._id,
          renderItem: _jsx(Quote, {
            content: quote.quoteText,
            name: quote.name,
            position: quote.position,
            type: quote.type
          })
        };
      })
    })
  });
};
export default QuoteSection;