import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
type QuoteProps = {
  content: string;
  name?: string;
  position?: string;
  type: string;
};
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1rblzhj",
  styles: "position:relative;left:0.5rem;margin-left:auto;margin-right:auto;display:flex;width:100%;flex-direction:column;justify-content:center;padding-left:2rem;padding-right:2rem;@media (min-width: 640px){padding-left:8rem;padding-right:8rem;}@media (min-width: 1024px){max-width:64rem;}@media (min-width: 1280px){max-width:1280px;padding-left:12rem;padding-right:12rem;}"
} : {
  name: "xtpfj1-Quote",
  styles: "position:relative;left:0.5rem;margin-left:auto;margin-right:auto;display:flex;width:100%;flex-direction:column;justify-content:center;padding-left:2rem;padding-right:2rem;@media (min-width: 640px){padding-left:8rem;padding-right:8rem;}@media (min-width: 1024px){max-width:64rem;}@media (min-width: 1280px){max-width:1280px;padding-left:12rem;padding-right:12rem;};label:Quote;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1t2bo6n",
  styles: "margin-bottom:0.75rem;font-size:14px;line-height:22.4px;font-weight:800;text-transform:uppercase;--tw-text-opacity:1;color:rgb(138 0 0 / var(--tw-text-opacity));@media (min-width: 1280px){font-size:18px;line-height:28.8px;}"
} : {
  name: "mi8esf-Quote",
  styles: "margin-bottom:0.75rem;font-size:14px;line-height:22.4px;font-weight:800;text-transform:uppercase;--tw-text-opacity:1;color:rgb(138 0 0 / var(--tw-text-opacity));@media (min-width: 1280px){font-size:18px;line-height:28.8px;};label:Quote;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "ct2i2i",
  styles: "margin-bottom:1.5rem;text-indent:-0.875rem;font-size:18px;line-height:28.8px;font-weight:700;--tw-text-opacity:1;color:rgb(138 0 0 / var(--tw-text-opacity));@media (min-width: 768px){text-indent:-1rem;font-size:20px;line-height:35px;}@media (min-width: 1280px){text-indent:-1.25rem;font-size:30px;line-height:39px;}"
} : {
  name: "qr0cnl-Quote",
  styles: "margin-bottom:1.5rem;text-indent:-0.875rem;font-size:18px;line-height:28.8px;font-weight:700;--tw-text-opacity:1;color:rgb(138 0 0 / var(--tw-text-opacity));@media (min-width: 768px){text-indent:-1rem;font-size:20px;line-height:35px;}@media (min-width: 1280px){text-indent:-1.25rem;font-size:30px;line-height:39px;};label:Quote;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "mj1cua",
  styles: "font-size:12px;line-height:24px;font-weight:700;--tw-text-opacity:1;color:rgb(0 0 0 / var(--tw-text-opacity));@media (min-width: 1280px){font-size:16px;line-height:25.6px;}"
} : {
  name: "2zpgpb-Quote",
  styles: "font-size:12px;line-height:24px;font-weight:700;--tw-text-opacity:1;color:rgb(0 0 0 / var(--tw-text-opacity));@media (min-width: 1280px){font-size:16px;line-height:25.6px;};label:Quote;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "c326dw",
  styles: "font-size:12px;line-height:24px;--tw-text-opacity:1;color:rgb(0 0 0 / var(--tw-text-opacity));@media (min-width: 1280px){font-size:14px;line-height:22.4px;}"
} : {
  name: "9ddbhy-Quote",
  styles: "font-size:12px;line-height:24px;--tw-text-opacity:1;color:rgb(0 0 0 / var(--tw-text-opacity));@media (min-width: 1280px){font-size:14px;line-height:22.4px;};label:Quote;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const Quote = ({
  content,
  name,
  position,
  type
}: QuoteProps) => {
  return _jsxs("figure", {
    css: _ref,
    children: [_jsx("p", {
      css: _ref2,
      children: type
    }), _jsxs("blockquote", {
      css: _ref3,
      children: ["\u201C ", content, "\u201D"]
    }), (name || position) && _jsxs("figcaption", {
      children: [_jsx("span", {
        css: _ref4,
        children: name ? name : "Anonymous"
      }), _jsx("br", {}), position && _jsx("span", {
        css: _ref5,
        children: position
      })]
    })]
  });
};
export default Quote;